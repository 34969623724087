<template>
  <div class="managedata">
    <Header />

    <div class="container mt-3">

        <button class="btn btn-info mt-3" @click="onShow()">เพิ่มข้อมูล</button>
        <div
          class="row mt-3"
          style="border: 1px solid #333; padding: 3%"
          v-if="show"
        >
          <form class="col-md-6">
            <div class="form-group">
              <label for="exampleInputEmail1">ชื่อ - นามสกุล</label>
              <input
                type="text"
                v-model="form.name"
                class="form-control"
                placeholder="กรอกชื่อ - นามสกุล"
              />
            </div>
            <div class="form-group">
              <label for="exampleInputPassword1">ตำแหน่ง</label>
              <input
                type="text"
                v-model="form.department"
                class="form-control"
                placeholder="กรอกตำแหน่ง"
              />
            </div>
            <div class="form-group">
              <label for="exampleInputPassword1">หน้าที่</label>
              <select class="form-control" v-model="form.type">
                <option selected value="ผู้ปฏิบัติ">ผู้ปฏิบัติ</option>
                <option value="ผู้ลงนาม">ผู้ลงนาม</option>
              </select>
            </div>
            <button type="button" @click="onShow()" class="btn btn-danger mt-2">
              ยกเลิก
            </button>
            <button
              type="button"
              class="btn btn-primary mt-2 ml-2"
              @click="onCreate()"
            >
              บันทึก
            </button>
          </form>
        </div>
        <table class="table table-hover mt-2">
          <thead>
            <tr>
              <th scope="col" style="width: 5%">#</th>
              <th scope="col" style="width: 20%">ชื่อ - นามสกุล</th>
              <th scope="col" style="width: 40%">ตำแหน่ง</th>
              <th scope="col" style="width: 10%">หน้าที่</th>
              <th scope="col" style="width: 10%">จัดการข้อมูล</th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="(item, index) in mackerList" :key="index">
              <th scope="row">{{ index + 1 }}</th>
              <td>{{ item.name }}</td>
              <td>{{ item.department }}</td>
              <td>{{ item.type }}</td>
              <td>
                <button
                  class="btn btn-outline-info btn-sm"
                  @click="onEdit(item)"
                >
                  Edit
                </button>
                <button
                  class="btn btn-outline-danger btn-sm ml-2"
                  @click="onDelete(item._id)"
                >
                  Delete
                </button>
              </td>
            </tr>
          </tbody>
        </table>
      </div>

      <div class="tab-pane fade" id="tabB" role="tabpanel" aria-labelledby="home-tab">
        <button class="btn btn-info mt-3" @click="onShow()">เพิ่มข้อมูล</button>
        <div
          class="row mt-3"
          style="border: 1px solid #333; padding: 3%"
          v-if="show"
        >
          <form class="col-md-6">
            <div class="form-group">
              <label for="exampleInputEmail1">ชื่อ - นามสกุล</label>
              <input
                type="text"
                v-model="form.name"
                class="form-control"
                placeholder="กรอกชื่อ - นามสกุล"
              />
            </div>
            <div class="form-group">
              <label for="exampleInputPassword1">ตำแหน่ง</label>
              <input
                type="text"
                v-model="form.department"
                class="form-control"
                placeholder="กรอกตำแหน่ง"
              />
            </div>
            <button type="button" @click="onShow()" class="btn btn-danger mt-2">
              ยกเลิก
            </button>
            <button
              type="button"
              class="btn btn-primary mt-2 ml-2"
              @click="onCreate()"
            >
              บันทึก
            </button>
          </form>
        </div>
        <table class="table table-hover mt-2">
          <thead>
            <tr>
              <th scope="col" style="width: 5%">#</th>
              <th scope="col" style="width: 20%">ชื่อ - นามสกุล</th>
              <th scope="col" style="width: 50%">ตำแหน่ง</th>
              <th scope="col" style="width: 10%">จัดการข้อมูล</th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="(item, index) in mackerList" :key="index">
              <th scope="row">{{ index + 1 }}</th>
              <td>{{ item.name }}</td>
              <td>{{ item.department }}</td>
              <td>
                <button
                  class="btn btn-outline-info btn-sm"
                  @click="onEdit(item)"
                >
                  Edit
                </button>
                <button
                  class="btn btn-outline-danger btn-sm ml-2"
                  @click="onDelete(item._id)"
                >
                  Delete
                </button>
              </td>
            </tr>
          </tbody>
        </table>
    </div>
  </div>
</template>

<script>
import Header from "@/components/Header.vue";
import axios from "axios";
import Swal from "sweetalert2";

export default {
  name: "ManageData",
  components: {
    Header,
  },
  data() {
    return {
      show: false,
      form: {
        name: "",
        department: "",
        type: ""
      },
      mackerList: "",
      id: "",
    };
  },
  mounted() {
    this.onFind();
  },
  methods: {
    onShow() {
      if (this.show) {
        this.show = false;
        this.id = "";
        this.form.name = "";
        this.form.department = "";
        this.form.type = "";
      } else {
        this.show = true;
      }
    },
    onFind() {
      axios.get("/api/macker").then((res) => {
        this.mackerList = res.data;
      });
    },
    onCreate() {
      if (this.id) {
        this.onUpdate();
      } else {
        if (this.form.name != "" && this.form.department) {
          axios.post("/api/macker", this.form).then((res) => {
            this.onFind();
            this.show = false;
            this.form.name = "";
            this.form.department = "";
            Swal.fire({
              icon: "success",
              title: "บันทึกสำเร็จ",
              showConfirmButton: false,
              timer: 1500,
            });
          });
        } else {
          Swal.fire({
            icon: "error",
            title: "กรุณากรอกข้อมูลให้ครบ!",
            showConfirmButton: false,
            timer: 1500,
          });
        }
      }
    },
    onDelete(id) {
      Swal.fire({
        title: "Are you sure?",
        text: "You won't be able to revert this!",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Yes, delete it!",
      }).then((result) => {
        if (result.isConfirmed) {
          axios.delete("/api/macker/" + id).then((res) => {
            this.onFind();
          });
        }
      });
    },
    onEdit(info) {
      this.show = true;
      this.id = info._id;
      this.form.name = info.name;
      this.form.department = info.department;
      this.form.type = info.type;
    },
    onUpdate() {
      if (this.id) {
        axios.put("/api/macker/" + this.id, this.form).then((res) => {
          this.onFind();
          this.show = false;
          this.form.name = "";
          this.form.department = "";
          this.id = "";
          Swal.fire({
            icon: "success",
            title: "บันทึกสำเร็จ",
            showConfirmButton: false,
            timer: 1500,
          });
        });
      }
    },
  },
};
</script>

<style scoped></style>
