<template>
  <div class="header">
    <header class="header header-2">
      <div class="navbar-area">
        <div class="container">
          <div class="row align-items-center">
            <div class="col-lg-12">
              <nav class="navbar navbar-expand-lg">
                <a class="navbar-brand" href="/">
                  <img src="@/assets/logo-cad.png" alt="Logo" />
                </a>
                <button class="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarSupportedContent2" aria-controls="navbarSupportedContent2" aria-expanded="false" aria-label="Toggle navigation">
                  <span class="toggler-icon"></span>
                  <span class="toggler-icon"></span>
                  <span class="toggler-icon"></span>
                </button>

                <div class="collapse navbar-collapse sub-menu-bar" id="navbarSupportedContent2">
                  <ul id="nav2" class="navbar-nav ml-auto">
                    <li class="nav-item">
                    </li>
                    <li class="nav-item">
                    </li>
                    <li class="nav-item">
                    </li>
                    <li class="nav-item">
                    </li>
                    <li class="nav-item">
                    </li>
                    <li class="nav-item">
                    </li>
                  </ul>
                  <a href="/manage" v-if="login" type="button" class="button2 button-sm radius-10-0 ml-3">จัดการข้อมูล</a>
                  <a type="button" v-if="!login" data-toggle="modal" data-target=".loginModal" class="button button-sm radius-10-0 ml-3">เข้าสู่ระบบ</a>
                  <a type="button" v-if="login" @click="onLogout()" class="button2 button-sm radius-10-0 ml-3">ออกจากระบบ</a>
                </div>
                <!-- navbar collapse -->
              </nav>
              <!-- navbar -->
            </div>
          </div>
          <!-- row -->
        </div>
        <!-- container -->
      </div>
      <!-- navbar area -->
      <div class="modal fade loginModal" id="loginModal" tabindex="-1" role="dialog" aria-labelledby="myLargeModalLabel" aria-hidden="true">
        <div class="modal-dialog modal-md">
          <div class="modal-content">
            <div class="modal-header">
              <h5 class="modal-title">เข้าสู่ระบบ</h5>
              <button type="button" class="close" style="background-color:transparent !important; border: 0;" data-dismiss="modal" aria-label="Close">
                <span aria-hidden="true">×</span>
              </button>
            </div>
            <div class="modal-body">
              <form class="modal-content animate" action="/action_page.php" method="post">
                <!-- <div class="imgcontainer">
                  <img src="/favicon.png" alt="Avatar" class="avatar">
                </div> -->

                <div class="container">
                  <label for="uname"><b>Username</b></label>
                  <input v-model="form.username" type="text" placeholder="Enter Username" name="uname" required>

                  <label for="psw"><b>Password</b></label>
                  <input v-model="form.password" type="password" placeholder="Enter Password" name="psw" required>
                    
                  <button class="login" @click="onLogin()" type="button">Login</button>
                </div>

              </form>
            </div>
          </div>
        </div>
      </div>
    </header>
  </div>
</template>

<script>
import axios from 'axios';
import Swal from 'sweetalert2';

export default {
  name: 'Header',
  components: {
    
  },
  data() {
    return {
      login: false,
      form: {
        username: '',
        password: ''
      },
      myModal: ''
    }
  },
  mounted() {
    this.onCheckLogin();
  },
  methods: {
    onLogin(){
      axios.post('/api/user', this.form).then(res => { 
        if (res.data == 'success') {
          this.onCheckLogin()
          window.location.reload();
        } else {
          Swal.fire({
          icon: 'error',
            title: 'เกิดข้อผิดพลาด...',
            text: 'Username หรือ Password ไม่ถูกต้อง!',
          })
        }
      })
    },
    onCheckLogin(){
      axios.get('/api/user').then(res => this.login = res.data )
    },
    onLogout(){
      axios.post('/api/user/logout').then(res => window.location.reload() )
    }
  },
}
</script>

<style scoped>
  a{
    cursor: pointer;
  }
  input[type=text], input[type=password] {
  width: 100%;
  padding: 12px 20px;
  margin: 8px 0;
  display: inline-block;
  border: 1px solid #ccc;
  box-sizing: border-box;
}

/* Set a style for all buttons */
.login {
  background-color: #04AA6D;
  color: white;
  padding: 14px 20px;
  margin: 8px 0;
  border: none;
  cursor: pointer;
  width: 100%;
}

.login:hover {
  opacity: 0.8;
}

/* Center the image and position the close button */
.imgcontainer {
  text-align: center;
  margin: 24px 0 12px 0;
  position: relative;
}

img.avatar {
  width: 40%;
  /* border-radius: 50%; */
}

span.psw {
  float: right;
  padding-top: 16px;
}


/* Modal Content/Box */
.modal-content {
  background-color: #fefefe;
  margin: 5% auto 10% auto; /* 5% from the top, 15% from the bottom and centered */
  border: 1px solid #888;
  width: 80%; /* Could be more or less, depending on screen size */
}

.close:hover,
.close:focus {
  color: red;
  cursor: pointer;
}

/* Add Zoom Animation */
.animate {
  -webkit-animation: animatezoom 0.6s;
  animation: animatezoom 0.6s
}

@-webkit-keyframes animatezoom {
  from {-webkit-transform: scale(0)} 
  to {-webkit-transform: scale(1)}
}
  
@keyframes animatezoom {
  from {transform: scale(0)} 
  to {transform: scale(1)}
}

/* Change styles for span and cancel button on extra small screens */
@media screen and (max-width: 300px) {
  span.psw {
     display: block;
     float: none;
  }
}
</style>
