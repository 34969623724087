<template>
  <div class="home">
    <Header />
    <section class="pricing-section pricing-style-1">
      <div class="container">
        <div class="row justify-content-center">
          <div class="col-lg-10 col-md-10 col-sm-10">
            <div class="single-pricing">
              <div class="row pl-5">
                <div class="col-md-5">
                  <div class="input-group mb-3">
                    <label class="input-group-text">ประเภทหนังสือ</label>
                    <select
                      class="form-select"
                      v-model="typeSelected"
                      @change="onSelectChange($event.target.value)"
                    >
                      <option value="all" selected>ทั้งหมด</option>
                      <option value="หนังสือลงนาม">หนังสือลงนาม</option>
                      <option value="หนังสือโต้ตอบ">หนังสือโต้ตอบ</option>
                    </select>
                  </div>
                </div>
                <div class="col-md-4">
                  <div class="input-group mb-3">
                    <label class="input-group-text">ปี</label>
                    <select
                      class="form-select"
                      v-model="yearSelected"
                      @change="onSelectYear($event.target.value)"
                    >
                      <option  v-for="(item, index) in yearList"
                      :key="index" :value="item._id">{{ item._id }}</option>
                    </select>
                  </div>
                </div>
                <div class="col-md-3">
                  <a
                    type="button"
                    data-toggle="modal"
                    @click="onInsert()"
                    data-target=".insert-modal"
                    class="button2 button-sm radius-10-0"
                    >ขอเลขหนังสือ</a
                  >
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
    <section class="p-4 mb-15">
      <h5 class="text-center" v-if="typeSelected != 'all'">
        {{ typeSelected }} ปี {{ yearSelected }}
      </h5>
      <h5 class="text-center" v-if="typeSelected == 'all'">
        ทะเบียนหนังสือ ปี {{ yearSelected }}
      </h5>
      <div class="table-responsive">
        <table id="tb-list" class="table table-bordered table-striped p-2">
          <thead>
            <tr>
              <th class="bg-primary text-light" scope="col" style="width: 12%">
                เลขทะเบียนหนังสือ
              </th>
              <th
                class="bg-primary text-light text-center"
                scope="col"
                style="width: 10%"
              >
                ที่หนังสือ
              </th>
              <th
                class="bg-primary text-light text-center"
                scope="col"
                style="width: 12%"
              >
                วันที่
              </th>
              <th
                class="bg-primary text-light text-center"
                scope="col"
                style="width: 10%"
              >
                จาก
              </th>
              <th
                class="bg-primary text-light text-center"
                scope="col"
                style="width: 10%"
              >
                ถึง
              </th>
              <th
                class="bg-primary text-light text-center"
                scope="col"
                style="width: 23%"
              >
                เรื่อง
              </th>
              <th
                class="bg-primary text-light text-center"
                scope="col"
                style="width: 10%"
              >
                ผู้ปฏิบัติ
              </th>
              <th
                class="bg-primary text-light text-center"
                scope="col"
                style="width: 18%"
              >
                จัดการข้อมูล
              </th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="(item, index) in bookList" :key="index">
              <td> ต. {{ onDisplayNumber(item.booknumber) }}/{{ item.bookyear }} </td>
              <td>{{ item.bookid }}</td>
              <td>{{ item.bookdate }}</td>
              <td>{{ item.bookfrom }}</td>
              <td>{{ item.bookto }}</td>
              <td>{{ item.booktitle }}</td>
              <td>{{ item.bookmaker }}</td>
              <td class="text-center">
                <button
                  type="button"
                  data-toggle="modal"
                  @click="onEdit(item)"
                  data-target=".edit-modal"
                  class="btn btn-sm btn-outline-info"
                >
                  <img
                    src="@/assets/pencil-square.svg"
                    class="pb-1"
                    alt="icon"
                  />
                </button>
                <button
                  type="button"
                  style="margin-left: 1%"
                  @click="onDelete(item._id)"
                  class="btn btn-sm btn-outline-danger"
                >
                  <img src="@/assets/trash.svg" class="pb-1" alt="icon" />
                </button>
                <button
                  type="button"
                  style="margin-left: 1%"
                  @click="onExport(item)"
                  class="btn btn-sm btn-outline-success"
                >
                  <img
                    src="@/assets/cloud-arrow-down-fill.svg"
                    class="pb-1"
                    alt="icon"
                  />
                </button>
              <p class="mt-2"> วันที่ปฏิบัติ {{ onDisplayDate(item.bookcreate) }} <br>
              <span style="color:red;" v-show="check_file(item._id) > 0" ><svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-cloud-check-fill" viewBox="0 0 16 16">
                <path d="M8 2a5.53 5.53 0 0 0-3.594 1.342c-.766.66-1.321 1.52-1.464 2.383C1.266 6.095 0 7.555 0 9.318 0 11.366 1.708 13 3.781 13h8.906C14.502 13 16 11.57 16 9.773c0-1.636-1.242-2.969-2.834-3.194C12.923 3.999 10.69 2 8 2zm2.354 4.854-3 3a.5.5 0 0 1-.708 0l-1.5-1.5a.5.5 0 1 1 .708-.708L7 8.793l2.646-2.647a.5.5 0 0 1 .708.708z"/>
              </svg></span> <span v-for="(info, i) in item.sendtype" :key="i"><svg style="color:blue; margin-left: 7px;" v-show="info == 'paper' " xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-book" viewBox="0 0 16 16">
                  <path d="M1 2.828c.885-.37 2.154-.769 3.388-.893 1.33-.134 2.458.063 3.112.752v9.746c-.935-.53-2.12-.603-3.213-.493-1.18.12-2.37.461-3.287.811V2.828zm7.5-.141c.654-.689 1.782-.886 3.112-.752 1.234.124 2.503.523 3.388.893v9.923c-.918-.35-2.107-.692-3.287-.81-1.094-.111-2.278-.039-3.213.492V2.687zM8 1.783C7.015.936 5.587.81 4.287.94c-1.514.153-3.042.672-3.994 1.105A.5.5 0 0 0 0 2.5v11a.5.5 0 0 0 .707.455c.882-.4 2.303-.881 3.68-1.02 1.409-.142 2.59.087 3.223.877a.5.5 0 0 0 .78 0c.633-.79 1.814-1.019 3.222-.877 1.378.139 2.8.62 3.681 1.02A.5.5 0 0 0 16 13.5v-11a.5.5 0 0 0-.293-.455c-.952-.433-2.48-.952-3.994-1.105C10.413.809 8.985.936 8 1.783z"/>
                </svg><svg style="color:green; margin-left: 10px;" v-show="info == 'e-doc'" xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-explicit-fill" viewBox="0 0 16 16">
  <path d="M2.5 0A2.5 2.5 0 0 0 0 2.5v11A2.5 2.5 0 0 0 2.5 16h11a2.5 2.5 0 0 0 2.5-2.5v-11A2.5 2.5 0 0 0 13.5 0h-11Zm4.326 10.88H10.5V12h-5V4.002h5v1.12H6.826V7.4h3.457v1.073H6.826v2.408Z"/>
</svg></span> </p>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
      <div
        class="modal fade insert-modal"
        tabindex="-1"
        role="dialog"
        aria-labelledby="myLargeModalLabel"
        aria-hidden="true"
      >
        <div class="modal-dialog modal-lg">
          <div class="modal-content">
            <div class="modal-header">
              <h5 class="modal-title" id="exampleModalLabel">
                เพิ่มข้อมูลหนังสือ
              </h5>
              <button
                type="button"
                class="close"
                style="background-color: transparent !important; border: 0"
                data-dismiss="modal"
                aria-label="Close"
              >
                <span aria-hidden="true">×</span>
              </button>
            </div>
            <div class="modal-body">
              <form>
                <div class="row">
                  <div class="input-group mb-3">
                    <label class="input-group-text">ประเภทหนังสือ</label>
                    <select
                      class="form-select"
                      v-model="form.booktype"
                      @change="onChangeBookType($event.target.value)"
                    >
                      <option value="หนังสือลงนาม" selected>
                        หนังสือลงนาม
                      </option>
                      <option value="หนังสือโต้ตอบ">หนังสือโต้ตอบ</option>
                    </select>
                  </div>
                  <div class="form-group col-lg-6">
                    <label for="inputEmail4">เลขทะเบียนส่ง</label>
                    <input
                      type="number"
                      min="0"
                      class="form-control"
                      v-model="form.booknumber"
                    />
                  </div>
                  <div class="form-group col-lg-6">
                    <label for="inputPassword4"></label>
                    <p class="mt-1">เลขส่งล่าสุด : {{ lastnumber }}</p>
                  </div>
                </div>
                <div class="row mt-2 g-1">
                  <div class="form-group col-lg-3">
                    <label for="inputEmail4">ที่หนังสือ</label>
                    <input
                      type="text"
                      class="form-control"
                      v-model="form.bookid"
                    />
                  </div>
                  <div class="form-group col-lg-3">
                    <label for="inputPassword4">ลงวันที่</label>
                    <input class="form-control" autocomplete="off" id="datepickerth" type="text" data-provide="datepicker" data-date-format="dd MM yyyy" data-date-language="th-th">
                  </div>
                  <div class="form-group col-lg-3">
                    <label for="inputEmail4">เบอร์โทรศัพท์</label>
                    <input
                      type="text"
                      class="form-control"
                      v-model="form.bookphone"
                    />
                  </div>
                  <div class="form-group col-lg-3" v-show="name">
                    <label for="inputEmail4">วันที่ต้นเรื่อง</label>
                    <input class="form-control" autocomplete="off" id="datepickerth-re" type="text" data-provide="datepicker" data-date-format="dd MM yyyy" data-date-language="th-th">
                  </div>
                </div>
                <div class="form-group mt-2">
                  <div class="input-group mt-2">
                    <input
                      type="file"
                      class="form-control"
                      @change="upload($event)"
                      multiple
                      id="file-input"
                    />
                    <label class="input-group-text" for="inputGroupFile02"
                      >เอกสารที่เกี่ยวข้อง</label
                    >
                  </div>
                </div>
                <div class="form-group mt-2">
                  <label for="inputAddress2">เรื่องหนังสือ</label>
                  <textarea
                    class="form-control"
                    rows="3"
                    v-model="form.booktitlename"
                  />
                </div>
                <div class="form-group mt-2">
                  <label for="inputAddress">จาก</label>
                  <input
                    type="text"
                    class="form-control"
                    v-model="form.bookfrom"
                  />
                </div>
                <div class="form-group mt-2">
                  <label for="inputAddress2">ถึง</label>
                  <input
                    type="text"
                    class="form-control"
                    v-model="form.bookto"
                  />
                </div>
                <div class="form-group mt-2">
                  <label for="inputAddress2">เรื่อง</label>
                  <textarea
                    class="form-control"
                    rows="3"
                    v-model="form.booktitle"
                  />
                </div>
                <div class="form-group mt-2">
                  <label for="inputAddress2">ผู้ปฏิบัติ</label>
                  <select class="form-control" v-model="form.bookmaker">
                    <option v-for="(item,i) in mackerListA" :key="i" :value="item.name"> {{ item.name }} </option>
                  </select>
                </div>
                <div class="row">
                  <div class="form-group mt-2 col-md-6">
                    <label for="inputAddress2">ผู้ลงนาม</label>
                    <select class="form-control" v-model="form.booksign" @change="onChangeSign($event.target.value)">
                      <option v-for="(item,i) in mackerListB" :key="i" :value="item.name"> {{ item.name }} </option>
                    </select>
                  </div>
                  <div class="form-group mt-2 col-md-6">
                    <label for="inputAddress2">ตำแหน่งผู้ลงนาม</label>
                    <input type="text" class="form-control" v-model="form.booksigndept" readonly>
                  </div>
                </div>
                <div class="mt-4">
                  <label for="">รูปแบบการจัดส่งหนังสือ  &nbsp; </label>
                  <div class="form-check form-check-inline">
                    <input class="form-check-input" type="checkbox" id="inlineCheckbox1" v-model="form.sendtype" value="e-doc">
                    <label class="form-check-label" for="inlineCheckbox1">e-doc</label>
                  </div>
                  <div class="form-check form-check-inline">
                    <input class="form-check-input" type="checkbox" id="inlineCheckbox2" v-model="form.sendtype" value="paper">
                    <label class="form-check-label" for="inlineCheckbox2">paper</label>
                  </div>
                </div>
                <div class="form-group mt-3">
                  <label for="inputAddress2">หมายเหตุ</label>
                  <input
                    type="text"
                    class="form-control"
                    v-model="form.bookremark"
                  />
                </div>
              </form>
            </div>
            <div class="modal-footer">
              <button
                type="button"
                class="btn btn-secondary"
                data-dismiss="modal"
              >
                ยกเลิก
              </button>
              <button
                type="button"
                @click="onSubmit('insert')"
                class="btn btn-primary"
              >
                บันทึก
              </button>
            </div>
          </div>
        </div>
      </div>

      <!-- EDIT MODAL -->

      <div
        class="modal fade edit-modal"
        id="edit-modal"
        tabindex="-1"
        role="dialog"
        aria-labelledby="myLargeModalLabel"
        aria-hidden="true"
      >
        <div class="modal-dialog modal-lg">
          <div class="modal-content">
            <div class="modal-header">
              <h5 class="modal-title" id="exampleModalLabel">
                แก้ไขข้อมูลหนังสือ
              </h5>
              <button
                type="button"
                class="close"
                style="background-color: transparent !important; border: 0"
                data-dismiss="modal"
                aria-label="Close"
              >
                <span aria-hidden="true">×</span>
              </button>
            </div>
            <div class="modal-body">
              <form>
                <div class="row">
                  <div class="input-group mb-3">
                    <label class="input-group-text">ประเภทหนังสือ</label>
                    <select class="form-select" v-model="form.booktype" @change="onChangeBookType($event.target.value)">
                      <option value="หนังสือลงนาม" selected>
                        หนังสือลงนาม
                      </option>
                      <option value="หนังสือโต้ตอบ">หนังสือโต้ตอบ</option>
                    </select>
                  </div>
                  <div class="form-group col-lg-6">
                    <label for="inputEmail4">เลขทะเบียนส่ง</label>
                    <input
                      type="number"
                      min="0"
                      class="form-control"
                      v-model="form.booknumber"
                    />
                  </div>
                </div>
                <div class="row mt-2 g-1">
                  <div class="form-group col-lg-3">
                    <label for="inputEmail4">ที่หนังสือ</label>
                    <input
                      type="text"
                      class="form-control"
                      v-model="form.bookid"
                    />
                  </div>
                  <div class="form-group col-lg-3">
                    <label for="inputPassword4">ลงวันที่</label>
                    <input class="form-control" autocomplete="off" id="datepickerth-ed" type="text" data-provide="datepicker" data-date-format="dd MM yyyy" data-date-language="th-th">
                  </div>
                  <div class="form-group col-lg-3">
                    <label for="inputEmail4">เบอร์โทรศัพท์</label>
                    <input
                      type="text"
                      class="form-control"
                      v-model="form.bookphone"
                    />
                  </div>
                  <div class="form-group col-lg-3" v-show="name">
                    <label for="inputPassword4">วันที่ต้นเรื่อง</label>
                    <input class="form-control" autocomplete="off" id="datepickerth-ed-reply" type="text" data-provide="datepicker" data-date-format="dd MM yyyy" data-date-language="th-th">
                  </div>
                </div>
                <div class="mt-4">
                  <div class="container">
                  <table class="table table-hover" v-if="this.form.bookfile != ''">
                    <thead>
                      <tr class="table-primary">
                        <th>File</th>
                        <th>Action</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr v-for="(item,index) in this.form.bookfile" :key="index">
                        <td>
                          เอกสารที่เกี่ยวข้อง {{ index + 1 }}
                        </td>
                        <td>
                          <img @click="onPdfView(item)" src="@/assets/envelope-open.svg" style="cursor: pointer;">
                          <img @click="onDeleteFile(item.files, index)" src="@/assets/trash.svg" style="cursor: pointer; margin-left: 3px;">
                        </td>
                      </tr>
                    </tbody>
                  </table>
                  </div>
                  <div class="input-group mt-2">
                    <input
                      type="file"
                      class="form-control"
                      @change="upload($event)"
                      multiple
                      id="file-input"
                    />
                    <label class="input-group-text" for="inputGroupFile02"
                      >เอกสารที่เกี่ยวข้อง</label
                    >
                  </div>
                </div>
                <div class="form-group mt-2">
                  <label for="inputAddress">เรื่องหนังสือ</label>
                  <input
                    type="text"
                    class="form-control"
                    v-model="form.booktitlename"
                  />
                </div>
                <div class="form-group mt-2">
                  <label for="inputAddress">จาก</label>
                  <input
                    type="text"
                    class="form-control"
                    v-model="form.bookfrom"
                  />
                </div>
                <div class="form-group mt-2">
                  <label for="inputAddress2">ถึง</label>
                  <input
                    type="text"
                    class="form-control"
                    v-model="form.bookto"
                  />
                </div>
                <div class="form-group mt-2">
                  <label for="inputAddress2">เรื่อง</label>
                  <textarea
                    class="form-control"
                    rows="3"
                    v-model="form.booktitle"
                  />
                </div>
                <div class="form-group mt-2">
                  <label for="inputAddress2">ผู้ปฏิบัติ</label>
                  <select class="form-control" v-model="form.bookmaker">
                    <option v-for="(item,i) in mackerListA" :key="i" :value="item.name"> {{ item.name }} </option>
                  </select>
                </div>
                <div class="row">
                  <div class="form-group mt-2 col-md-6">
                    <label for="inputAddress2">ผู้ลงนาม</label>
                    <select class="form-control" v-model="form.booksign" @change="onChangeSign($event.target.value)">
                      <option v-for="(item,i) in mackerListB" :key="i" :value="item.name"> {{ item.name }} </option>
                    </select>
                  </div>
                  <div class="form-group mt-2 col-md-6">
                    <label for="inputAddress2">ตำแหน่งผู้ลงนาม</label>
                      <input type="text" class="form-control" readonly v-model="form.booksigndept">
                  </div>
                </div>
                <div class="mt-4">
                  <label for="">รูปแบบการจัดส่งหนังสือ  &nbsp; </label>
                  <div class="form-check form-check-inline">
                    <input class="form-check-input" type="checkbox" id="edinlineCheckbox1" v-model="form.sendtype" value="e-doc">
                    <label class="form-check-label" for="inlineCheckbox1">e-doc</label>
                  </div>
                  <div class="form-check form-check-inline">
                    <input class="form-check-input" type="checkbox" id="edinlineCheckbox2" v-model="form.sendtype" value="paper">
                    <label class="form-check-label" for="inlineCheckbox2">paper</label>
                  </div>
                </div>
                <div class="form-group mt-3">
                  <label for="inputAddress2">หมายเหตุ</label>
                  <input
                    type="text"
                    class="form-control"
                    v-model="form.bookremark"
                  />
                </div>
              </form>
            </div>
            <div class="modal-footer">
              <button
                type="button"
                class="btn btn-secondary"
                data-dismiss="modal"
              >
                ยกเลิก
              </button>
              <button
                type="button"
                @click="onSubmit('edit')"
                class="btn btn-primary"
              >
                บันทึก
              </button>
            </div>
          </div>
        </div>
      </div>
    </section>
    <Footer />
  </div>
</template>

<script>
import Header from "@/components/Header.vue";
import Footer from "@/components/Footer.vue";
import Docxtemplater from "docxtemplater";
import PizZip from "pizzip";
import PizZipUtils from "pizzip/utils/index.js";
import { saveAs } from "file-saver";
import axios from "axios";
import Swal from "sweetalert2";
import { required } from "vuelidate/lib/validators";
import useVuelidate from "@vuelidate/core";
import moment from 'moment';

export default {
  name: "HomeView",
  components: {
    Header,
    Footer,
  },
  setup() {
    return { v$: useVuelidate() };
  },
  computed : {
    checkedComputed () {
      return this.form.sendtype;
    }
  },
  data() {
    return {
      lastnumber: "0000",
      form: {
        booknumber: "",
        bookid: "",
        bookdate: "",
        bookfrom: "",
        bookto: "",
        booktitle: "",
        bookmaker: "",
        bookremark: "",
        bookreplydate: "",
        booktitlename: "ส่งสำเนาหนังสือ",
        booktype: "",
        bookfile: [],
        bookyear: "",
        booksign: "",
        booksigndept: "",
        bookphone: "",
        bookcreate: new Date(),
        sendtype: []
      },
      bookList: "",
      typeSelected: "หนังสือโต้ตอบ",
      yearList: "",
      yearSelected: new Date().getFullYear() + 543,
      mackerList: "",
      mackerListA: "",
      mackerListB: "",
      name: false,
      fileList: ''
    };
  },
  validations: {
    form: {
      booknumber: { required },
      bookid: { required },
      bookdate: { required },
      bookfrom: { required },
      bookto: { required },
      booktitle: { required },
      bookmaker: { required },
      booktype: { required },
      booksign: { required },
      booktitlename: { required },
      booksigndept: { required },
      bookphone: { required }
    },
  },
  mounted() {
    this.onFind();

    axios.get('/api/files').then(res => {
        this.fileList = res.data;
    })

    $('.datepicker').datepicker({
        autoclose: true
    });

  },
  methods: {
    onDisplayNumber(year){
      if (year < 10) {
        return ('000' + year)
      }else if (year >= 10 && year < 100) {
        return ('00' + year)
      }else if (year >= 100 && year < 1000) {
        return ('0' + year)
      }else{
        return (year)
      }
    },
    onDisplayDate(date){
      return moment(String(date)).add(543,'year').locale('th').format('DD/MM/YYYY')
    },
    check_file(info_id){
       return this.fileList.filter(item => 
        item.info_id == info_id
      ).length
    },
    onInsert() {
      this.form.booknumber = "";
      this.form.bookid = "";
      this.form.bookdate = "";
      this.form.bookfrom = "";
      this.form.bookto = "";
      this.form.booktitlename = "ส่งสำเนาหนังสือ";
      this.form.bookreplydate = "";
      this.form.booktitle = "";
      this.form.bookmaker = "";
      this.form.bookremark = "";
      this.form.booktype = "";
      this.form.bookfile = [];
      this.form.bookyear = "";
      this.form.booksign = "";
      this.form.booksigndept = "";
      this.form.bookphone = "";
      this.form.bookcreate = "";
      this.form.sendtype = [];
    },
    onChangeBookType(value) {

      if (value == 'หนังสือลงนาม') {
        this.name = false;
        this.form.booktitlename = "ขอส่งสำเนาหนังสือลงนาม / ขอส่งหนังสือลงนามต้นฉบับ";
      } else {
        this.name = true;
        this.form.booktitlename = "ส่งสำเนาหนังสือสั่งการ";
      }

      this.form.booktype = value;

      axios
        .get("/api/info/lastnumber/type/" + this.form.booktype)
        .then((res) => {
          if (res.data == "no data") {
            this.lastnumber = "0000";
            this.form.booknumber = 1;
          } else {
            this.form.booknumber = res.data[0].booknumber + 1;
            if (res.data[0].booknumber >= 1 && res.data[0].booknumber < 10) {
              this.lastnumber = "000" + res.data[0].booknumber.toString();
            } else if (
              res.data[0].booknumber >= 10 &&
              res.data[0].booknumber < 100
            ) {
              this.lastnumber = "00" + res.data[0].booknumber.toString();
            } else if (
              res.data[0].booknumber >= 100 &&
              res.data[0].booknumber < 1000
            ) {
              this.lastnumber = "0" + res.data[0].booknumber.toString();
            } else {
              this.lastnumber = res.data.booknumber[0].toString();
            }
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },
    onFind() {
      axios.get(`/api/info/groupyear`).then((res) => {
        if (res.data == "no data") {
          this.yearList = [
            {
              _id: "no data on database",
            },
          ];
        } else {
          this.yearList = res.data;
        }
      });

      axios.get(`/api/macker`).then((res) => {
        let data = res.data;
        this.mackerList = data;
        this.mackerListA = data.filter(item => item.type === 'ผู้ปฏิบัติ');
        this.mackerListB = data.filter(item => item.type === 'ผู้ลงนาม');
      });

      axios
        .get(`/api/info/${this.typeSelected}/${this.yearSelected}`)
        .then((res) => {
          this.bookList = res.data;

          $(document).ready(function () {
            $("#tb-list").DataTable({
              pageLength: 25,
              lengthMenu: [
                [10, 25, 50, -1],
                [10, 25, 50, "All"],
              ],
              columnDefs: [{ orderable: false, targets: -1 }],
              order:[[0, 'desc']],
              language: {
                lengthMenu: "แสดงข้อมูล _MENU_ แถวต่อหน้า",
                zeroRecords: "ไม่พบข้อมูล!",
                info: "หน้า _PAGE_ จาก _PAGES_",
                infoEmpty: "No records available",
                infoFiltered: "(filtered from _MAX_ total records)",
                paginate: {
                  previous: "ก่อนหน้า",
                  next: "ต่อไป",
                },
                search: "ค้นหา",
              },
              bDestroy: true,
            });
          });
        });
    },
    onEdit(info) {
      this.form.booknumber = "";
      this.form.bookid = "";
      this.form.bookdate = "";
      this.form.bookfrom = "";
      this.form.bookto = "";
      this.form.booktitle = "";
      this.form.bookmaker = "";
      this.form.bookremark = "";
      this.form.bookreplydate = "";
      this.form.booktitlename = "";
      this.form.booktype = "";
      this.form.bookfile = [];
      this.form.bookyear = "";
      this.form.booksign = "";
      this.form.booksigndept = "";
      this.form.bookphone = "";
      this.form.bookcreate = "";
      this.form.sendtype = [];

      axios.get('/api/files/' + info._id).then(res => {
         this.form._id = info._id;
          this.form.booknumber = info.booknumber;
          this.form.bookid = info.bookid;
          this.form.bookdate = info.bookdate;
          this.form.bookfrom = info.bookfrom;
          this.form.bookto = info.bookto;
          this.form.booktitle = info.booktitle;
          this.form.bookmaker = info.bookmaker;
          this.form.bookremark = info.bookremark;
          this.form.booktype = info.booktype;
          this.form.bookfile = res.data;
          this.form.bookyear = info.year;
          this.form.booksign = info.booksign;
          this.form.booksigndept = info.booksigndept;
          this.form.bookphone = info.bookphone;
          this.form.booktitlename = info.booktitlename;
          this.form.bookreplydate = info.bookreplydate;
          this.form.bookcreate = info.bookcreate;
          this.form.sendtype = info.sendtype;
      })

      if (info.bookreplydate) {
        this.name = true
      } else {
        this.name = false
      }

      document.getElementById("datepickerth-ed").value = info.bookdate;
      document.getElementById("datepickerth-ed-reply").value = info.bookreplydate;
    },
    onPdfView(file) {
      // localStorage.setItem("pdfview", info);
      let openPdf = this.$router.resolve({ name: "pdfview", params: { file: file.files, type: this.form.booktype, number: this.form.booknumber } });
      window.open(openPdf.href);
    },
    onDelete(id) {
      Swal.fire({
        title: "Are you sure?",
        text: "You won't be able to revert this!",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Yes, delete it!",
      }).then((result) => {
        if (result.isConfirmed) {
          axios.delete(`/api/info/${id}`).then((res) => {
            Swal.fire("Deleted!", "Your file has been deleted.", "success");
            setTimeout(() => {
              window.location.reload();
            }, 1500);
          });
        }
      });
    },
    onDeleteFile(id, index){
      Swal.fire({
        title: "Are you sure?",
        text: "You won't be able to revert this!",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Yes, delete it!",
      }).then((result) => {
        if (result.isConfirmed) {
          axios.delete(`/api/files/${this.form.booktype}/${this.form.booknumber}/${id}`).then((res) => {
            this.form.bookfile.splice(index, 1)
            Swal.fire("Deleted!", "Your file has been deleted.", "success");
          });
        }
      });
    },
    onSelectChange(value) {

      this.typeSelected = value;

      axios
        .get(`/api/info/${this.typeSelected}/${this.yearSelected}`)
        .then((res) => {
          this.bookList = res.data;

          $('#tb-list').DataTable().destroy();
          $(document).ready(function () {

            $("#tb-list").DataTable({
              pageLength: 25,
              lengthMenu: [
                [10, 25, 50, -1],
                [10, 25, 50, "All"],
              ],
              columnDefs: [{ orderable: false, targets: -1 }],
              order:[[0, 'desc']],
              language: {
                lengthMenu: "แสดงข้อมูล _MENU_ แถวต่อหน้า",
                zeroRecords: "ไม่พบข้อมูล!",
                info: "หน้า _PAGE_ จาก _PAGES_",
                infoEmpty: "No records available",
                infoFiltered: "(filtered from _MAX_ total records)",
                paginate: {
                  previous: "ก่อนหน้า",
                  next: "ต่อไป",
                },
                search: "ค้นหา",
              },
              bDestroy: true,
            });
          });
        });
    },
    onSelectYear(value) {
      this.yearSelected = value;

      axios
        .get(`/api/info/${this.typeSelected}/${this.yearSelected}`)
        .then((res) => {
          this.bookList = res.data;

          $('#tb-list').DataTable().destroy();

          $(document).ready(function () {
            $("#tb-list").DataTable({
              pageLength: 25,
              lengthMenu: [
                [10, 25, 50, -1],
                [10, 25, 50, "All"],
              ],
              columnDefs: [{ orderable: false, targets: -1 }],
              order:[[0, 'desc']],
              language: {
                lengthMenu: "แสดงข้อมูล _MENU_ แถวต่อหน้า",
                zeroRecords: "ไม่พบข้อมูล!",
                info: "หน้า _PAGE_ จาก _PAGES_",
                infoEmpty: "No records available",
                infoFiltered: "(filtered from _MAX_ total records)",
                paginate: {
                  previous: "ก่อนหน้า",
                  next: "ต่อไป",
                },
                search: "ค้นหา",
              },
              bDestroy: true,
            });
          });
        });
    },
    upload(event) {
      let file = event.target.files;

      for (let index = 0; index < file.length; index++) {
        this.form.bookfile.push(file[index])
      }
    },
    thaiNumber(num) {
      var array = {
        1: "๑",
        2: "๒",
        3: "๓",
        4: "๔",
        5: "๕",
        6: "๖",
        7: "๗",
        8: "๘",
        9: "๙",
        0: "๐",
      };
      var str = num.toString();
      for (var val in array) {
        str = str.split(val).join(array[val]);
      }
      return str;
    },
    async onSubmit(type) {
      if (type == "insert") {
        if (this.form.booktype === 'หนังสือโต้ตอบ') {
          if (document.getElementById("datepickerth-re").value === '') {
            Swal.fire({
                  icon: "error",
                  title: "เกิดข้อผิดพลาด...",
                  text: "กรุณากรอกวันที่ต้นเรื่อง",
                });
          } else {
            this.form.bookcreate = new Date();
            this.form.bookdate = document.getElementById("datepickerth").value;
            if(this.name) this.form.bookreplydate = document.getElementById("datepickerth-re").value;
            const result = await this.v$.$validate();

            this.form.bookyear = this.form.bookdate.substring(
              this.form.bookdate.length,
              this.form.bookdate.length - 4
            );

            this.form.sendtype = this.checkedComputed;

            let formData = new FormData();
            for(let i =0; i < this.form.bookfile.length; i++) {
              formData.append("files", this.form.bookfile[i]);
            }
            formData.append('booknumber', this.form.booknumber)
            formData.append('bookid', this.form.bookid)
            formData.append('bookdate', this.form.bookdate)
            formData.append('bookfrom', this.form.bookfrom)
            formData.append('bookto', this.form.bookto)
            formData.append('booktitlename', this.form.booktitlename)
            formData.append('bookreplydate', this.form.bookreplydate)
            formData.append('booktitle', this.form.booktitle)
            formData.append('bookmaker', this.form.bookmaker)
            formData.append('bookremark', this.form.bookremark)
            formData.append('booktype', this.form.booktype)
            formData.append('bookfile', this.form.bookfile)
            formData.append('bookyear', this.form.bookyear)
            formData.append('booksign', this.form.booksign)
            formData.append('booksigndept', this.form.booksigndept)
            formData.append('bookphone', this.form.bookphone)
            formData.append('bookcreate', this.form.bookcreate)
            for(let i =0; i < this.form.sendtype.length; i++) {
              formData.append("sendtype", this.form.sendtype[i]);
            }

            if (result) {
              axios.post(`/api/info/${this.form.booktype}/${this.form.booknumber}`, formData , {
                headers: {
                  "Content-Type": "multipart/form-data"
                }
              }).then((res) => {
                if (res.data === 'id duplicate') {
                  Swal.fire({
                    icon: "error",
                    title: "เกิดข้อผิดพลาด...",
                    text: "เลขทะเบียนหนังสือซ้ำ!",
                  });
                } else {
                  Swal.fire({
                  icon: "success",
                  title: "บันทึกสำเร็จ",
                  showConfirmButton: false,
                  timer: 1500,
                });

                window.location.reload();
                this.onFind();
                }
              });
            } else {
              Swal.fire({
                icon: "error",
                title: "เกิดข้อผิดพลาด...",
                text: "กรุณากรอกข้อมูลให้ครบ!",
              });
            }
          }
        } else {
          this.form.bookcreate = new Date();
          this.form.bookdate = document.getElementById("datepickerth").value;
          if(this.name) this.form.bookreplydate = document.getElementById("datepickerth-re").value;
          const result = await this.v$.$validate();

          this.form.bookyear = this.form.bookdate.substring(
            this.form.bookdate.length,
            this.form.bookdate.length - 4
          );

          this.form.sendtype = this.checkedComputed;

           let formData = new FormData();
            for(let i =0; i < this.form.bookfile.length; i++) {
              formData.append("files", this.form.bookfile[i]);
            }
            formData.append('booknumber', this.form.booknumber)
            formData.append('bookid', this.form.bookid)
            formData.append('bookdate', this.form.bookdate)
            formData.append('bookfrom', this.form.bookfrom)
            formData.append('bookto', this.form.bookto)
            formData.append('booktitlename', this.form.booktitlename)
            formData.append('bookreplydate', this.form.bookreplydate)
            formData.append('booktitle', this.form.booktitle)
            formData.append('bookmaker', this.form.bookmaker)
            formData.append('bookremark', this.form.bookremark)
            formData.append('booktype', this.form.booktype)
            formData.append('bookfile', this.form.bookfile)
            formData.append('bookyear', this.form.bookyear)
            formData.append('booksign', this.form.booksign)
            formData.append('booksigndept', this.form.booksigndept)
            formData.append('bookphone', this.form.bookphone)
            formData.append('bookcreate', this.form.bookcreate)
            for(let i =0; i < this.form.sendtype.length; i++) {
              formData.append("sendtype", this.form.sendtype[i]);
            }

          if (result) {
            axios.post(`/api/info/${this.form.booktype}/${this.form.booknumber}`, formData , {
                headers: {
                  "Content-Type": "multipart/form-data"
                }
              }).then((res) => {
              if (res.data === 'id duplicate') {
                Swal.fire({
                  icon: "error",
                  title: "เกิดข้อผิดพลาด...",
                  text: "เลขทะเบียนหนังสือซ้ำ!",
                });
              } else {
                Swal.fire({
                icon: "success",
                title: "บันทึกสำเร็จ",
                showConfirmButton: false,
                timer: 1500,
              });

              window.location.reload();
              this.onFind();
              }
            });
          } else {
            Swal.fire({
              icon: "error",
              title: "เกิดข้อผิดพลาด...",
              text: "กรุณากรอกข้อมูลให้ครบ!",
            });
          }
        }
      } else {
        this.form.bookdate = document.getElementById("datepickerth-ed").value;
        this.form.bookreplydate = document.getElementById("datepickerth-ed-reply").value;
        const result = await this.v$.$validate();

        this.form.bookyear = this.form.bookdate.substring(
          this.form.bookdate.length,
          this.form.bookdate.length - 4
        );

            let formData = new FormData();
            for(let i =0; i < this.form.bookfile.length; i++) {
              formData.append("files", this.form.bookfile[i]);
            }
            formData.append('booknumber', this.form.booknumber)
            formData.append('bookid', this.form.bookid)
            formData.append('bookdate', this.form.bookdate)
            formData.append('bookfrom', this.form.bookfrom)
            formData.append('bookto', this.form.bookto)
            formData.append('booktitlename', this.form.booktitlename)
            formData.append('bookreplydate', this.form.bookreplydate)
            formData.append('booktitle', this.form.booktitle)
            formData.append('bookmaker', this.form.bookmaker)
            formData.append('bookremark', this.form.bookremark)
            formData.append('booktype', this.form.booktype)
            formData.append('bookfile', this.form.bookfile)
            formData.append('bookyear', this.form.bookyear)
            formData.append('booksign', this.form.booksign)
            formData.append('booksigndept', this.form.booksigndept)
            formData.append('bookphone', this.form.bookphone)
            formData.append('bookcreate', this.form.bookcreate)
            
            if (this.form.sendtype.length == 0) {
               formData.append("sendtype", this.form.sendtype);
            } else {
              for(let i =0; i < this.form.sendtype.length; i++) {
                formData.append("sendtype", this.form.sendtype[i]);
              }
            }

        if (result) {
          axios.put(`/api/info/${this.form.booktype}/${this.form.booknumber}/${this.form._id}`, formData).then((res) => {
            Swal.fire({
              icon: "success",
              title: "บันทึกสำเร็จ",
              showConfirmButton: false,
              timer: 1500,
            });

            window.location.reload();
            this.onFind();
          });
        } else {
          Swal.fire({
            icon: "error",
            title: "เกิดข้อผิดพลาด...",
            text: "กรุณากรอกข้อมูลให้ครบ!",
          });
        }
      }
    },
    loadFile(url, callback) {
      PizZipUtils.getBinaryContent(url, callback);
    },
    onExport(info) {

      let booknumber, bookdate, booknumberen = "";

      if (info.booknumber < 10) {
        booknumber = this.thaiNumber('000' + info.booknumber.toString())
        booknumberen = '000' + info.booknumber.toString()
      }

      if (info.booknumber > 9 && info.booknumber < 100) {
        booknumber = this.thaiNumber('00' + info.booknumber.toString())
        booknumberen = '00' + info.booknumber.toString()
      }

      if (info.booknumber > 99 && info.booknumber < 1000) {
        booknumber = this.thaiNumber('0' + info.booknumber.toString())
        booknumberen = '0' + info.booknumber.toString()
      }

      if (info.booknumber > 999) {
        booknumber = this.thaiNumber(info.booknumber.toString())
        booknumberen = '000' + info.booknumber.toString()
      }
      
      bookdate = this.thaiNumber(info.bookdate);
      
      if (info.bookreplydate) {
        this.thaiNumber(info.bookreplydate);
      }
      
      if (info.booktype == 'หนังสือลงนาม') {
      let filename = 'ลงนาม ต.' + booknumberen + '/' + info.bookyear + ' (' + info.booktitle + ')';
          this.loadFile(
            "/booktemplate/name.docx",
            function (error, content) {
              if (error) {
                throw error;
              }
              const zip = new PizZip(content);
              const doc = new Docxtemplater(zip, {
                paragraphLoop: true,
                linebreaks: true,
              });

              let splits = info.booksigndept.split(" ");

              doc.render({
                bookid: info.bookid,
                booknumber: booknumber,
                bookdate: bookdate,
                bookphone: info.bookphone,
                booktitlename: info.booktitlename,
                bookto: info.bookto,
                booktitle: info.booktitle,
                booksign: info.booksign,
                bookmaker: info.bookmaker,
                booksigndept: splits
              });

              const out = doc.getZip().generate({
                type: "blob",
                mimeType:
                  "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
              });
              saveAs(out, filename + ".docx");
            }
          );
    
      } else {
        let filename = 'โต้ตอบ ต.' + booknumberen + '/' + info.bookyear + ' (' + info.booktitle + ')';
        this.loadFile(
            "/booktemplate/reply.docx",
            function (error, content) {
              if (error) {
                throw error;
              }
              const zip = new PizZip(content);
              const doc = new Docxtemplater(zip, {
                paragraphLoop: true,
                linebreaks: true,
              });

              let splits = info.booksigndept.split(" ");

              doc.render({
                bookid: info.bookid,
                booknumber: booknumber,
                bookdate: bookdate,
                bookphone: info.bookphone,
                booktitlename: info.booktitlename,
                bookreplydate: info.bookreplydate,
                bookfrom: info.bookfrom,
                bookto: info.bookto,
                booktitle: info.booktitle,
                booksign: info.booksign,
                bookmaker: info.bookmaker,
                booksigndept: splits
              });

              const out = doc.getZip().generate({
                type: "blob",
                mimeType:
                  "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
              });
              saveAs(out, filename + ".docx");
            })
      }

    },
    onChangeSign(value){
      let dept = this.mackerList.filter(item => item.name == value);
      this.form.booksigndept = dept[0].department;
    }
  },
};
</script>

<style scoped>
select {
  max-width: 300px;
  border-radius: 50px;
}

.material-symbols-rounded {
  font-variation-settings: "FILL" 0, "wght" 400, "GRAD" 0, "opsz" 48;
}

.relative{
  position: relative;
}

.absolute{
  position: absolute;
  left: 0;
}

.absolute2{
  position: absolute;
  right: 0;
}
</style>
