<template>
  <div class="footer">
    <footer class="footer footer-style-1">
      <div class="container">
        <div class="copyright-wrapper">
          <p>Copyright © 2022 Chiang Mai University, All rights reserved. by Central Administration Division : Office Of the President</p>
        </div>
      </div>
    </footer>
  </div>
</template>

<script>
export default {
  name: 'Footer',
  components: {
    
  }
}
</script>