<template>
  <div class="pdfview">
    <!-- <vue-pdf-embed :source="link" /> -->
    <iframe :src="link" type="application/pdf" frameborder="0" style="position:fixed; top:0; left:0; bottom:0; right:0; width:100%; height:100%; border:none; margin:0; padding:0; overflow:hidden; z-index:999999;"></iframe>
  </div>
</template>

<script>
import axios from "axios";
import VuePdfEmbed from 'vue-pdf-embed'

export default {
  name: "PdfView",
  components: {
    VuePdfEmbed
  },
  data() {
    return {
      link: "",
    };
  },
  mounted() {
    let type = this.$route.params.type;
    let number = this.$route.params.number;
    let file = this.$route.params.file;

    this.link = "/api/uploads/" + type + '/' + number + '/' + file;
  },
  methods: {
    
  },
};
</script>

<style scoped></style>
