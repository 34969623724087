<template>
  <div class="login">
    <div class="card" style="width: 18rem">
      <div class="card-body">
        <div class="mt-3">
          <img src="@/assets/logo-cad.png" alt="headerlogo" width="100%" />
        </div>
        <div class="mt-5">
          <div class="input-group mb-3">
            <label class="input-group-text"
              ><img src="@/assets/person-circle.svg" alt="username"
            /></label>
            <input
              type="text"
              class="form-control"
              placeholder="Enter Username..."
              id="username"
              v-on:keyup.enter="onLogin()"
            />
          </div>
          <div class="input-group mb-3">
            <label class="input-group-text"
              ><img src="@/assets/lock-fill.svg" alt="password"
            /></label>
            <input
              type="password"
              class="form-control"
              placeholder="Enter Password..."
              id="password"
              v-on:keyup.enter="onLogin()"
            />
          </div>
        </div>
        <div>
          <button
            type="button"
            class="btn btn-primary form-control mt-3"
            @click="onLogin()"
          >
            LOG IN
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import Swal from 'sweetalert2';

export default {
  name: "Login",
  components: {},
  data() {
    return {};
  },
  methods: {
    onLogin() {
      let username = document.getElementById("username").value;
      let password = document.getElementById("password").value;
      axios
        .post("/api/user", {
          username: username,
          password: password,
        })
        .then((res) => {
          if (res.data == "success") {
            this.$router.push({ name: "home" });
          } else {
            Swal.fire({
              icon: "error",
              title: "เกิดข้อผิดพลาด...",
              text: "Username หรือ Password ไม่ถูกต้อง!",
            });
          }
        });
    },
  },
  mounted() {
    axios.get("/api/user").then((res) => {
      if (res.data !== false) {
        this.$router.push({ name: "home" });
      }
    });
  },
};
</script>

<style scoped>
html,
body {
  height: 100vh;
}
body {
  margin: 0%;
}
.card {
  min-height: 350px;
  min-width: 370px;
  opacity: 0.8;
}
.login {
  background: url('../assets/bg.png');
  background-repeat: no-repeat;
  background-size: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100vh;
}
</style>
