<template>
  <div class="container">
    <Header />

    <table class="table table-hover mt-2" id="loggerTable">
      <thead>
        <tr>
          <th scope="col" style="width: 5%">#</th>
          <th scope="col">User</th>
          <th scope="col">Action</th>
          <th scope="col">Timestamp</th>
        </tr>
      </thead>
      <tbody>
        <tr v-for="(item, index) in loggerList" :key="index">
          <th scope="row">{{ index + 1 }}</th>
          <td>{{ item.user }}</td>
          <td>
            <span v-show="item.action == 'deleted'" style="color:red;">{{ item.action }}</span>
            <span v-show="item.action == 'created'" style="color:green;">{{ item.action }}</span>
            <span v-show="item.action == 'updated'" style="color:blue;">{{ item.action }}</span>
          </td>
          <td>{{ onDisplayDate(item.date) }} น.</td>
        </tr>
      </tbody>
    </table>
  </div>
</template>

<script>
import Header from "@/components/Header.vue";
import axios from "axios";
import moment from 'moment';

export default {
  name: "ManageData",
  components: {
    Header,
  },
  data() {
    return {
      loggerList: "",
    };
  },
  mounted() {
    this.onFind();
  },
  methods: {
    onFind() {
      axios.get("/api/logger").then((res) => {
        this.loggerList = res.data;
        $(document).ready(function () {
            $("#loggerTable").DataTable({
              pageLength: 25,
              lengthMenu: [
                [10, 25, 50, -1],
                [10, 25, 50, "All"],
              ],
              columnDefs: [{ orderable: false, targets: -1 }],
              language: {
                lengthMenu: "แสดงข้อมูล _MENU_ แถวต่อหน้า",
                zeroRecords: "ไม่พบข้อมูล!",
                info: "หน้า _PAGE_ จาก _PAGES_",
                infoEmpty: "No records available",
                infoFiltered: "(filtered from _MAX_ total records)",
                paginate: {
                  previous: "ก่อนหน้า",
                  next: "ต่อไป",
                },
                search: "ค้นหา",
              },
              bDestroy: true,
            });
          });
      });
    },
    onDisplayDate(date){
      return moment(String(date)).add(543,'year').locale('th').format('DD/MM/YYYY HH:mm')
    },
  },
};
</script>

<style scoped></style>
