<template>
  <router-view/>
</template>

<style>
@import url('https://fonts.googleapis.com/css2?family=K2D:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800&display=swap');
html,*{
  font-family: 'K2D', sans-serif !important;
}
</style>
