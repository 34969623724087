import { createRouter, createWebHistory } from 'vue-router'
import HomeView from '../views/HomeView.vue';
import Login from '../views/Login.vue';
import pdfview from '../views/PdfView.vue';
import manage from '../views/ManageData.vue';
import logger from '../views/Logger.vue';
import Axios from 'axios';

const routes = [
  {
    path: '/',
    name: 'home',
    component: HomeView,
    meta: { auth: true }
  },
  {
    path: '/login',
    name: 'login',
    component: Login
  },
  {
    path: '/pdfview/:type/:number/:file',
    name: 'pdfview',
    component: pdfview
  },
  {
    path: '/manage',
    name: 'manage',
    component: manage,
    meta: { auth: true }
  },
  {
    path: '/logger',
    name: 'logger',
    component: logger,
    meta: { auth: true }
  },
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

router.beforeEach((to, from, next) => {
  const auth = to.meta.auth;
  if (!auth) return next();
  Axios.get('/api/user')
      .then(res => {
          if (res.data == false) {
              next({ name: 'login' });
          }else{
            return next()
          }
      })
      .catch(err => {
          next({ name: 'login' });
      })
})

export default router
